import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import ReactDOM from "react-dom/client";
import App from "./App";

const msalConfig = {
  auth: {
    clientId: "f66e76f8-c125-4364-bc75-a5ccaf9cb340",
    redirectUri: window.location.protocol + "//" + window.location.host,
    postLogoutRedirectUri: window.location.protocol + "//" + window.location.host,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);
