import React from "react";
import "./App.scss";
import "./index.css";
import { CCButton, ErrorCard, GuestHeader } from "@streetsheaver/sh-ui";
import { ReactComponent as CompucareLogo } from "./images/Compucare.svg";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useWindowDimensions } from "./utils/mediaQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@shfortawesome/pro-regular-svg-icons";

function determineAutomaticTheme() {
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark";
  } else {
    return "light";
  }
}

function getConsentLink(appId, state) {
  const redirect = new URL(window.location.protocol + "//" + window.location.host);
  return new URL(
    `https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=${appId}&redirect_uri=${redirect}&state=${state}&scope=https://graph.microsoft.com/.default`
  );
}

function App() {
  const { width } = useWindowDimensions();

  const { instance: msalInstance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const params = new URLSearchParams(document.location.search);
  const error = params.get("error");
  const state = error ? 0 : (parseInt(params.get("state")) || 0);
  const errorDescription = params.get("error_description");

  const customErrorContent = () => {
    return (
      <div className='consent'>
        <h2 className='status'>ERROR</h2>
        {error && <p className={"statusText"}>{error?.replace("_", " ")?.toUpperCase()}</p>}
        <p>{errorDescription ?? "An unknown error occured when granting permission. Please try again."}</p>
        <CCButton type={"primary"} content={"Try again"} takeContainerWidth mobile={width <= 1279} onClick={() => window.location.href = window.location.protocol + "//" + window.location.host} />
      </div>
    );
  };

  const customSuccessContent = () => {
    return (
      <div className='consent'>
        <h2 className='status'>Success</h2>
        {error && <p className={"statusText"}>{error?.replace("_", " ")?.toUpperCase()}</p>}
        <p>{errorDescription ?? "Admin consent successfully granted!"}</p>
        <CCButton type={"primary"} content={"Go to homepage"} takeContainerWidth mobile={width <= 1279} onClick={() => window.location.href = "http://compucare.streets-heaver.com"} />
      </div>
    );
  };

  const customContent = () => {
    return (
      <div className='consent'>
        <p className="title">Please consent to use Compucare.</p>
        <div className="consentApp">
          <p><span className="number">1.</span> Tenants Data Source</p>
          <CCButton content="Consent" disabled={state !== 0} onClick={() => window.location.href = getConsentLink("93c2c2b7-62a3-44a7-9a44-37ad6e6712e4", 1)} />
          <FontAwesomeIcon icon={state > 0 ? faCircleCheck : faCircle } className={state > 0 ? "enabled" : "disabled"} />
        </div>
        <div className="consentApp">
          <p><span className="number">2.</span> Compucare</p>
          <CCButton content="Consent" disabled={state !== 1} onClick={() => window.location.href = getConsentLink("f66e76f8-c125-4364-bc75-a5ccaf9cb340", 2)} />
          <FontAwesomeIcon icon={state > 1 ? faCircleCheck : faCircle} className={state > 1 ? "enabled" : "disabled"} />
        </div>
        <div className="consentApp">
          <p><span className="number">3.</span> Client Console</p>
          <CCButton content="Consent" disabled={state !== 2} onClick={() => window.location.href = getConsentLink("cdf3f8be-3c73-4f69-a0da-7690107708bd", 3)} />
          <FontAwesomeIcon icon={state > 2 ? faCircleCheck : faCircle} className={state > 2 ? "enabled" : "disabled"} />
        </div>
        <div className="consentApp">
          <p><span className="number">4.</span> Compucare Ward</p>
          <CCButton content="Consent" disabled={state !== 3} onClick={() => window.location.href = getConsentLink("bebb9cb0-74bb-4f0b-8826-df6f54eea28d", 4)} />
          <FontAwesomeIcon icon={state > 3 ? faCircleCheck : faCircle} className={state > 3 ? "enabled" : "disabled"} />
        </div>
        <div className="consentApp">
          <p><span className="number">5.</span> Compucare 8</p>
          <CCButton content="Consent" disabled={state !== 4} onClick={() => window.location.href = `https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=45404467-1a84-4afd-8a21-36d3b94b0e4b&redirect_uri=${new URL(window.location.protocol + "//" + window.location.host)}&state=5&scope=.default&nonce=abcde&prompt=consent`} />
          <FontAwesomeIcon icon={state > 4 ? faCircleCheck : faCircle} className={state > 4 ? "enabled" : "disabled"} />
        </div>
        <div className="consentApp">
          <p><span className="number">4.</span> Compucare Clinician</p>
          <CCButton content="Consent" disabled={state !== 5} onClick={() => window.location.href = getConsentLink("1c297e4a-4dc4-4a7a-a03f-3e7ae4f18e49", 6)} />
          <FontAwesomeIcon icon={state > 5 ? faCircleCheck : faCircle} className={state > 5 ? "enabled" : "disabled"} />
        </div>
      </div>
    );
  };

  return (
    <div className='outerContainer' data-theme={determineAutomaticTheme()}>
      <div className='consentPage'>
        <GuestHeader
          appTitle='Compucare'
          appIcon={<CompucareLogo />}
          showSignOut={isAuthenticated}
          onSignInClick={() => msalInstance.loginRedirect()}
          onSignOutClick={() => msalInstance.logoutRedirect()}
        />
        <div className='pageContent'>
          <div className='contentBox'>
            <ErrorCard customContent={(error || errorDescription) ? customErrorContent : state === 6 ? customSuccessContent : customContent} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
